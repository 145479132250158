import { SpecificPageDataContainerBase } from "@/store/models/shared/SpecificPageDataContainerBase";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { RequestModel } from "@/store/models/request/RequestModel";
import { IdeaModel } from "@/store/models/request/IdeaModel";

export class SpecificBankDataContainer extends SpecificPageDataContainerBase {
    bankInfoSectionData: any;
    requestIdeaSectionData: {
        requests: {
            critical: RequestModel[],
            high: RequestModel[],
            normal: RequestModel[],
            low: RequestModel[],
        } | null,
        ideas: IdeaModel[],
        requestsCount: number,
        ideasCount: number
    };

    constructor(obj: any) {
        super(obj);

        this.bankInfoSectionData = {
            bank: new BankModelMin(obj.stats),
            stats: {
                score: obj.stats.score,
                importance: obj.stats.importance,
                gap: obj.stats.gap,
                scores: obj.stats.scores || 0,
                answeredQuestions: obj.additional_data.answered_questions,
                banks: obj.additional_data.banks,
                countries: obj.additional_data.countries,
                productAreas: obj.additional_data.product_areas,
                lastFeedbackDate: obj.additional_data.last_feedback_at,
            }
        };

        this.requestIdeaSectionData = this.getRequestIdeaSectionData(obj.requests, obj.ideas);

    }

    getRequestIdeaSectionData(requestsData: any, ideasData: any) {
        let ideas = [];
        let requests = {
            critical: [],
            high: [],
            normal: [],
            low: [],
        };
        let requestsCount = 0;
        let ideasCount = 0;

        if (requestsData) {
            for (let [type, data] of Object.entries(requestsData)) {
                // @ts-ignore
                requests[type] = data && data.length ? data.map(rq => new RequestModel(rq)) : [];
            }

            // @ts-ignore
            requestsCount = Object.values(requests).reduce((a, e: any) => a + e.length, 0)
        }

        if (ideasData) {
            ideas = ideasData.map(id => new IdeaModel(id));
            ideasCount = ideas.length;
        }

        return { requests, ideas, requestsCount, ideasCount };
    }
}
