<template>
    <div class="bank-info-section section-card">
        <div class="bank-info-section__image">
            <img :src="bankInfoSectionData.bank.icon_path" alt="">
        </div>
        <div class="bank-info-section__content">
            <div class="bank-info-section__name">
                {{ bankInfoSectionData.bank.name }}
            </div>
            <div class="bank-info-section__content--status">
                <div class="status-section">
                    <div class="status-section__left">
                        <div class="status-section-item">
                            <h4>{{ $t("Average Score") }}</h4>
                            <span>{{ numberDecimalsFormat(bankInfoSectionData.stats.score) }}</span>
                        </div>
                        <div class="status-section-item">
                            <h4>{{ $t("Average Importance") }}</h4>
                            <span>{{ numberDecimalsFormat(bankInfoSectionData.stats.importance) }}</span>
                        </div>
                    </div>
                    <div class="status-section__right">
                        <div class="status-section-item" :class="getGapStatusClass(bankInfoSectionData.stats.gap)">
                            <h4>{{ $t("Average Gap") }}</h4>
                            <span>{{ numberDecimalsFormat(bankInfoSectionData.stats.gap) }}</span>
                        </div>
                        <div class="status-section-item">
                            <h4>{{ $t("Last Feedback Date") }}</h4>
                            <span>
                                {{ bankInfoSectionData.stats.lastFeedbackDate ? moment(bankInfoSectionData.stats.lastFeedbackDate).format('DD MMM YYYY') : $t('No Data') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="questions-replied">
                    <div class="questions-replied__value">
                        <img src="/media/buying/icons/questions-replied.svg" alt="">
                        <h4>{{ bankInfoSectionData.stats.answeredQuestions }}</h4>
                    </div>
                    <span>{{ $t("Questions Replied") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Constants } from "@/core/config/constants";
import { getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import moment from "moment";

export default {
    name: "BankInfoSection",
    props: {
        bankInfoSectionData: Object
    },
    data() {
        return {
            Constants,
            moment,
            getGapStatusClass,
            numberDecimalsFormat
        };
    }
};
</script>

<style scoped lang="scss">
.bank-info-section {
    padding: 16px;
    display: flex;
    gap: 20px;

    &__image {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: max-content;
        overflow: hidden;
        height: max-content;

        img {
            width: 127px;
            height: 127px;
        }
    }

    &__name {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #000000;
        opacity: 0.87;
    }

    &__content {
        width: calc(100% - 147px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .status-section {
                display: flex;

                &__left {
                    display: flex;
                    gap: 12px;
                    padding-right: 28px;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                &__right {
                    display: flex;
                    gap: 12px;
                    margin-left: 28px;

                    .status-section-item:last-child {
                        span {
                            font-size: 18px;
                            line-height: 13px;
                        }
                    }
                }

                .status-section-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    border: 1px solid #E5E5E5;
                    border-radius: 8px;
                    min-width: 139px;

                    h4 {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 13px;
                        color: #9A9A9A;
                        opacity: 0.87;
                        margin-bottom: 16px;
                    }

                    span {
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 13px;
                        color: #212121;
                    }

                    &.status-danger {
                        border-color: #e83227;

                        span {
                            color: #CE2419;
                        }
                    }

                    &.status-success {
                        border-color: #63B775;

                        span {
                            color: #63B775;
                        }
                    }
                }
            }

            .questions-replied {
                &__value {
                    position: relative;
                    margin-bottom: 16px;

                    h4 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-weight: 700;
                        font-size: 36px;
                        color: #000000;
                        opacity: 0.87;
                        margin-bottom: 0;
                    }
                }

                span {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 13px;
                    color: #717171;
                    opacity: 0.87;
                }
            }
        }
    }

    @media (max-width: 1440px) {
        &__name {
            margin-bottom: 10px;
        }
        &__content--status {
            .status-section {
                flex-direction: column;
                gap: 12px;

                &__left {
                    border-right: 0;
                    padding-right: 0;
                }

                &__right {
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        &__content {
            width: 100%;
        }
    }
    @media (max-width: 550px) {
        &__content--status {
            flex-direction: column;
            align-items: flex-start;

            .status-section {
                width: 100%;

                > div {
                    width: 100%;
                }

                .status-section-item {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 400px) {
        align-items: center;
        &__name {
            text-align: center;
        }
        &__content {
            &--status {
                align-items: center;
                gap: 20px;

                .status-section {
                    > div {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
</style>
