<template>
    <div class="specific-bank" v-if="!loading">
        <router-link class="back-previous-screen" to="/business/dashboard/banks">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Banks") }) }}
        </router-link>

        <el-select
            v-model="bankId"
            class="banks-select"
            :disabled="!specificBankDataContainer"
            @change="handleBankChange"
        >
            <el-option
                v-for="(item, index) in businessBanks"
                :key="index"
                :label="item.name"
                :value="item.id"
            />
        </el-select>

        <SpecificBanksFilter
            ref="specificBankFilter"
            :class="{'disabled-filter': !specificBankDataContainer}"
            :dashboardSingleBankFiltersState="dashboardSingleBankFiltersState"
            :disabled="!specificBankDataContainer"
            @onApplyFilters="fetchBanksData"
        />

        <template v-if="specificBankDataContainer">
            <div class="specific-bank__bank-info">
                <BankInfoSection
                    :bankInfoSectionData="specificBankDataContainer.bankInfoSectionData"
                />
            </div>

            <div class="specific-bank__bank-status">
                <div class="development-over-time">
                    <span class="bank-status-title">{{ $t("Development over time") }}</span>
                    <div class="section-card">
                        <LineChart :chartData="specificBankDataContainer.dotChartSectionData" />
                    </div>
                </div>
                <div class="bank-stats">
                    <span class="bank-status-title">{{ $t("Bank stats") }}</span>
                    <div class="section-card">
                        <div class="bank-stats__item">
                            <div class="bank-stats__item--value">
                                <inline-svg src="/media/buying/icons/product-areas-icon.svg" width="54px"
                                            height="54px" />
                                <span>{{ specificBankDataContainer.bankInfoSectionData.stats.productAreas }}</span>
                            </div>
                            <div class="bank-stats__item--title">{{ $t("Product Areas") }}</div>
                        </div>
                        <div class="bank-stats__item">
                            <div class="bank-stats__item--value">
                                <inline-svg src="/media/buying/icons/map-black-icon.svg" width="54px" height="54px" />
                                <span>{{ specificBankDataContainer.bankInfoSectionData.stats.countries }}</span>
                            </div>
                            <div class="bank-stats__item--title">{{ $t("Countries") }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="specific-bank__list-data">
                <div class="list-data-item">
                    <DashboardCommonList
                        :page-type="BusinessCommonListEnum.PRODUCT_AREA"
                        :score-data="specificBankDataContainer.productAreaSectionData"
                        :dataContainer="specificBankDataContainer"
                        :filter-data="dashboardSingleBankFiltersState"
                        hide-view-all
                        hide-footer
                    />
                </div>
                <div class="list-data-item">
                    <DashboardCommonList
                        :page-type="BusinessCommonListEnum.COUNTRIES"
                        :score-data="specificBankDataContainer.countrySectionData"
                        :dataContainer="specificBankDataContainer"
                        :filter-data="dashboardSingleBankFiltersState"
                        hide-view-all
                        hide-footer
                    />
                </div>
            </div>

            <div class="specific-bank__request-idea">
                <DashboardRequestsIdeas
                    :requestIdeaSectionData="specificBankDataContainer.requestIdeaSectionData"
                />
            </div>

            <div class="specific-bank__questions">
                <QuestionsTabsContainer
                    :benchmark-questions-list="specificBankDataContainer.questionsSectionData.questions"
                    :custom-questions="specificBankDataContainer.customQuestionsSectionData"
                    :is-loading="specificBankDataContainer.questionsSectionData.isLoading"
                    @onSortingChange="handleSortingChange"
                    @onClickQuestionItem="handleClickQuestionItem"
                    @onTabChange="questionActiveTab = $event"
                />

                <CustomPagination
                    v-if="specificBankDataContainer.questionsSectionData.pagination &&
                        questionActiveTab === QUESTIONS_TABS.BENCHMARK_QUESTIONS"
                    class="mt-2"
                    :disabled="specificBankDataContainer.questionsSectionData.isLoading"
                    :current-page="specificBankDataContainer.questionsSectionData.page"
                    :total="specificBankDataContainer.questionsSectionData.pagination.totalItems"
                    :pageSize="specificBankDataContainer.questionsSectionData.limit"
                    @currentPageChange="handleCurrentPageChange"
                    @pageSizeChange="handlePageSizeChange"
                />
            </div>
        </template>
        <CustomLoader v-else />
    </div>
</template>

<script>
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import SpecificBanksFilter from "@/buying-teams/pages/business/dashboard/components/SpecificBanksFilter";
import BankInfoSection from "@/buying-teams/pages/business/dashboard/components/banks/BankInfoSection";
import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import DashboardCommonList from "@/buying-teams/shared-components/dashboard/DashboardCommonList";
import DashboardRequestsIdeas from "@/buying-teams/pages/business/dashboard/components/overview/DashboardRequestsIdeas";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import { diagLog } from "@/core/helpers/GlobalHelper";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { SpecificBankDataContainer } from "@/store/models/business/dashboard/level3/SpecificBankDataContainer";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";
import { QUESTIONS_TABS } from "@/core/config/constants";

export default {
    name: "SpecificBank",
    components: {
        QuestionsTabsContainer,
        CustomLoader,
        CustomPagination,
        DashboardRequestsIdeas,
        DashboardCommonList,
        BankInfoSection,
        SpecificBanksFilter,
        LineChart
    },
    data() {
        return {
            loading: true,
            BusinessCommonListEnum,
            specificBankDataContainer: null,
            bankId: null,
            QUESTIONS_TABS,
            questionActiveTab: QUESTIONS_TABS.BENCHMARK_QUESTIONS,
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        businessBanks() {
            return store.getters.businessBanks;
        },
        dashboardSingleBankFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardSingleBank
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    beforeMount() {
        this.bankId = this.$route.params.id ? Number(this.$route.params.id) : null;
        if (!this.bankId) {
            redirectToRouteWithName("business-dashboard-banks");
        }
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_SINGLE_BANK);
        this.loading = false;
        await this.fetchBanksData(this.dashboardSingleBankFiltersState);
    },
    methods: {
        handleCurrentPageChange(page) {
            this.specificBankDataContainer.questionsSectionData.page = page;
            this.fetchQuestionsData();
        },
        handlePageSizeChange(size) {
            this.specificBankDataContainer.questionsSectionData.page = 1;
            this.specificBankDataContainer.questionsSectionData.limit = size;
            this.fetchQuestionsData();
        },
        setFiltersDefaultState() {
            this.$refs.specificBankFilter.setFiltersDefaultState();
        },
        handleBankChange(e) {
            this.$router.replace({ name: "business-dashboard-specific-banks", params: { id: e } });
            this.setFiltersDefaultState();
            this.$refs.specificBankFilter.setFilterApplied();
            this.fetchBanksData(this.$refs.specificBankFilter.filterData || this.dashboardSingleBankFiltersState);
        },
        handleSortingChange(sorting) {
            this.specificBankDataContainer.questionsSectionData.page = 1;
            this.specificBankDataContainer.questionsSectionData.orderKey = sorting;
            this.specificBankDataContainer.addQuestionsSectionData([], null,  true);
            this.fetchQuestionsData();
        },
        async handleClickQuestionItem(question, newTab) {
            await store.dispatch('moveQuestionFilters', {
                type: BusinessCommonListEnum.BANKS,
                pageFilterData: this.dashboardSingleBankFiltersState,
                itemId: this.bankId
            });

            const questionUrl = `/business/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        },
        async fetchBanksData(filter = null) {
            try {
                this.specificBankDataContainer = null;
                let res = await store.dispatch("fetchSpecificBankData", { filter, bankId: this.bankId });
                this.specificBankDataContainer = new SpecificBankDataContainer(res);

                this.fetchQuestionsData();
                diagLog("PAGE DATA ", this.specificBankDataContainer);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchQuestionsData() {
            if (this.specificBankDataContainer.questionsSectionData.isLoading) {
                return;
            }

            this.specificBankDataContainer.questionsSectionData.isLoading = true;
            try {
                let payload = {
                    page: this.specificBankDataContainer.questionsSectionData.page,
                    limit: this.specificBankDataContainer.questionsSectionData.limit,
                    order_key: this.specificBankDataContainer.questionsSectionData.orderKey,
                    order_direction: this.specificBankDataContainer.questionsSectionData.orderDirection,
                    filter: {
                        ...this.$refs.specificBankFilter.filterData,
                        bank_ids: [this.$route.params.id]
                    },
                    type: "sub_menu"
                };

                let res = await store.dispatch("fetchBusinessDashboardQuestions", payload);
                diagLog(`fetched res for page ${payload.page}`, { res, payload });
                this.specificBankDataContainer.addQuestionsSectionData(res.questions, res.paginationMetadata);
                this.specificBankDataContainer.customQuestionsSectionData.setCustomQuestionsAnalyticsData(res.customQuestions);
            } catch (e) {
                console.error({ e });
            } finally {
                this.specificBankDataContainer.questionsSectionData.isLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.specific-bank {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .specific-product-area-filter {
        &.disabled-filter {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 4;
                background: #F6F6F6;
                opacity: 0.5;
                border-radius: 17px;
            }
        }
    }

    .section-card {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;

        .section-card-header {
            height: 62px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-card-title {
            display: flex;
            align-items: center;
            gap: 8px;

            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .section-card-actions {
            display: flex;
            align-items: center;
            gap: 22px;

            > a {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }

            &__sort {
                width: 28px;
                height: 28px;
                background: transparent;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .banks-select {
        margin-top: 10px;

        .el-input__inner {
            padding-left: 10px;
            height: 33px;
            border-bottom: 2px solid #363636;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.87);
            width: 276px;
        }

        .el-input__suffix svg path {
            fill: #000000;
        }
    }

    &__bank-info {
        width: 100%;
    }

    &__bank-status {
        width: 100%;
        display: flex;
        gap: 26px;

        .development-over-time {
            width: calc(100% - 290px);

            .section-card {
                padding: 10px;
                overflow: hidden;
            }
        }

        .bank-status-title {
            margin-left: 15px;
            margin-bottom: 7px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #434343;
            opacity: 0.6;
            display: block;
        }

        .bank-stats {
            width: 290px;

            .section-card {
                width: 100%;
                height: calc(100% - 22px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            }

            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;

                &--value {
                    position: relative;

                    svg path {
                        fill: #F2F2F2;
                    }

                    span {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 13px;
                        color: rgba(0, 0, 0, 0.87);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--title {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 13px;
                    color: #717171;
                    opacity: 0.87;
                }
            }
        }
    }

    &__list-data {
        width: 100%;
        display: flex;
        gap: 12px;

        .list-data-item {
            width: 100%;
        }
    }

    &__request-idea {
        width: 100%;
    }

    &__questions {
        width: 100%;
    }

    @media (max-width: 768px) {
        &__bank-status {
            flex-direction: column;

            .development-over-time, .bank-stats {
                width: 100%;
            }

            .bank-stats {
                .section-card {
                    flex-direction: row;
                    padding: 20px 0;
                }
            }
        }
        &__list-data {
            flex-direction: column;
        }
    }
}
</style>
